<template>
  <div
    :class="{
      content: true,
      page: page.total > queryParams.Limit,
    }"
    ref="content"
  >
    <div class="main-content">
      <div class="search-box" ref="search">
        <a-form>
          <a-row>
            <a-col :sm="6" :xxl="4">
              <e-input
                title="标题"
                :labelCol="6"
                :valueCol="16"
                v-model:value="queryParams.Name"
              />
            </a-col>
            <a-col :sm="4" :xxl="3">
              <e-input-number
                title="餐标"
                :labelCol="6"
                :valueCol="16"
                v-model:value="queryParams.UnitPrice"
              />
            </a-col>
            <a-col :sm="4" :xxl="3">
              <e-input-number
                title="人数"
                :labelCol="6"
                :valueCol="16"
                v-model:value="queryParams.PeopleCount"
              />
            </a-col>
            <a-col :sm="6" :xxl="4">
              <e-radio
                title="分组"
                :labelCol="4"
                :valueCol="18"
                v-model:value="queryParams.GourpShow"
                :options="[{
                  label: '启用分组',
                  value: true
                }, {
                  label: '正常显示',
                  value: false
                }]"
              />
            </a-col>

            <a-col>
              <a-button type="primary" @click="getDataList(1)"> 查询 </a-button>
              <a-button
                type="primary"
                @click="reset(() => resetFun())"
                danger
                style="margin-left: 10px"
              >
                重置
              </a-button>
              <a-button
                type="primary"
                style="margin-left: 10px"
                @click="$router.push({name: 'chef-combo-form'})"
              >
                添加套餐
              </a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="data-list">
        <a-table
          :columns="columns"
          :data-source="list"
          :pagination="false"
          :loading="loading"
          :row-key="(record) => record.id"
          :scroll="{ y: height, x: width }"
        >
          <template #bodyCell="{ column, record, index }">
            <template v-if="column.key === 'index'">
              {{ (page.page - 1) * queryParams.Limit + index + 1 }}
            </template>

            <template v-if="column.key === 'cpPrice'">
              ¥{{ stockPrice(record)}}
            </template>

            <template v-if="column.key === 'price'">
              ¥{{ record.price }}
            </template>

            <template v-if="column.key === 'sellingPrice'">
              ¥{{ record.sellingPrice }}
            </template>

            <template v-if="column.key === 'rate'">
              {{(((record.unitPrice * record.peopleCount - record.price) / (record.unitPrice * record.peopleCount)) * 100).toFixed(2)}} %
            </template>

            <template v-if="column.key === 'action'">
              <a-tooltip>
                <template #title>套餐复制</template>
                <a @click.stop="$refs.comboCopy.open(record)">
                  <CopyOutlined />
                </a>
              </a-tooltip>
              <a-divider type="vertical" />
              <a-tooltip>
                <template #title>编辑</template>
                <a @click.stop="$router.push({name: 'chef-combo-form-edit', params: {id: record.id}})">
                  <EditOutlined />
                </a>
              </a-tooltip>
              <a-divider type="vertical" />
              <a-tooltip>
                <template #title>删除</template>
                <a @click.stop="delForm(() => delFormFun(record))">
                  <DeleteOutlined />
                </a>
              </a-tooltip>
            </template>
          </template>
        </a-table>
      </div>
    </div>

    <div class="pagination">
      <a-pagination
        :total="page.total"
        v-model:current="page.page"
        v-model:page-size="queryParams.Limit"
        :hide-on-single-page="true"
        :show-size-changer="true"
        :page-size-options="['20', '40', '100', '200']"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
      >
        <template #itemRender="{ page, type }">
          <a v-if="type === 'prev'" class="ant-pagination-item-link">上一页</a>
          <a v-else-if="type === 'next'" class="ant-pagination-item-link">
            下一页
          </a>
          <span v-else>{{ page }}</span>
        </template>
      </a-pagination>
      <span>数据总数：{{ page.total }}</span>
    </div>
    <ComboCopy ref="comboCopy" @ok="getDataList(1)" />
  </div>
</template>

<script>
import { defineComponent, ref, createVNode } from 'vue'
import {
  ExclamationCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  CopyOutlined
} from '@ant-design/icons-vue'
import { ComboClass } from '@/apis/combo'
import page from '@/mixins/page'
import ComboCopy from '@/components/forms/combo/Copy'

const api = new ComboClass()
export default defineComponent({
  mixins: [page],
  components: {
    EditOutlined,
    DeleteOutlined,
    ComboCopy,
    CopyOutlined
  },
  setup () {
    const loading = ref(false)
    const height = ref(500)
    const width = ref(500)

    const columns = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 60
      },
      {
        title: '套餐名称',
        key: 'name',
        dataIndex: 'name'
      },
      {
        title: '餐标',
        key: 'unitPrice',
        dataIndex: 'unitPrice',
        width: 100
      },
      {
        title: '人数',
        key: 'peopleCount',
        dataIndex: 'peopleCount',
        width: 100
      },
      {
        title: '套餐价格',
        key: 'sellingPrice',
        dataIndex: 'sellingPrice',
        width: 100
      },
      {
        title: '菜品成本价',
        key: 'price',
        dataIndex: 'price',
        width: 110
      },
      {
        title: '菜品售价',
        key: 'cpPrice',
        dataIndex: 'cpPrice',
        width: 100
      },
      {
        title: '毛利率',
        key: 'rate',
        dataIndex: 'rate',
        width: 100
      },
      {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        width: 110
      }
    ]
    return {
      columns,
      loading,
      height,
      width
    }
  },
  created () {
    this.getDataList(1)
  },
  methods: {
    stockPrice (record) {
      const items = record.comboDishes
      const num = record.peopleCount
      let res = 0
      items.map((e) => {
        if (e.isPersonal) {
          // 人均配菜
          res += e.dishSellingPrice * e.count * num
        } else {
          res += e.dishSellingPrice * e.count
        }
      })

      res = res.toFixed(2)
      return Number(res)
    },
    getDataList (page) {
      this.loading = true
      this.page.page = page
      const q = { ...this.queryParams }
      q.Offset = (page - 1) * q.Limit
      api.gets(q).then((resp) => {
        this.list = resp.data
        this.page.total = resp.total
        this.loading = false
        this.getTableHeight()
      })
    },
    resetFun () {
      this.queryParams = {
        Limit: 20
      }

      this.getDataList(1)
    },
    delFormFun (record) {
      const _this = this
      _this.$confirm({
        title: '确定删除?',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode(
          'div',
          {
            style: 'color:red;'
          },
          `即将删除数据：${record.name}`
        ),

        onOk () {
          api.del(record.id).then(() => {
            _this.$message.success('删除成功！')
            _this.getDataList(1)
          })
        }
      })
    },

    getTableHeight () {
      const paddingBottom = this.page.total > this.queryParams.Limit ? 50 : 9
      const mainPadding = 30
      const thead = 56
      this.height =
        this.$refs.content.offsetHeight -
        this.$refs.search.offsetHeight -
        thead -
        paddingBottom -
        mainPadding

      this.width = this.$refs.search.offsetWidth - 10
    }
  }
})
</script>

<style lang="less" scoped>
</style>
